export default class GooglePayAPI {
    static async processPayment(paymentData: any) {
        const paymentEndPoint = paymentData.paymentProcessor === 'authorize.net' ? '/process-auth-gpay' : '/process-stripe-gpay';
        let response = await (fetch(
            process.env.REACT_APP_SERVER_URL + paymentEndPoint,
            {
                method: 'POST',
                body: JSON.stringify({
                    token: paymentData.token,
                    amount: paymentData.amount,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ));
        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }
}