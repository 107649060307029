import { Col, Modal, Row, Image, Button } from 'react-bootstrap';
import { createRef, useState } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import '../../features/customer/profileUpload.scss'
import '../../../node_modules/cropperjs/dist/cropper.css'
import RotateIMG from '../../assets/images/rotate-ccw.png'
import CustomerDetailsAPI from '../../API/customerDetailsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer, setCustomer } from './customerSlice';
import { loadCustomerDetails } from './customerDetailSlice';

interface Props {
    image: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    setUserImage: (updatingImage: any) => void;
    //setImageVersion: (imageVersion: any) => void;
}

export default function ProfileUpload({ image, showModal, setShowModal, setUserImage }: Props) {
    const dispatch = useDispatch();
    const cropperRef = createRef<ReactCropperElement>();
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState<number>(0);
    const custData = useSelector(selectCustomer);
    const [busy, setBusy] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const imageExt = ['JPEG', 'jpeg', 'JPG', 'jpg', 'PNG', 'png'];

    const handleZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZoom(parseFloat(e.target.value));
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));
    };

    const handleZoomOut = () => {
        if (zoom !== 0.1) {
            setZoom((prevZoom) => prevZoom - 0.1);
        }
        if (zoom < 0.1) {
            setZoom(0.1);
        }
    };

    const handleRotate = () => {
        setRotation((prevRotation) => prevRotation + 90);
    };

    const handleSaveImage = async () => {
        setBusy(true);
      
        const cropper = cropperRef.current?.cropper;
        const croppedCanvas = cropper?.getCroppedCanvas({
          width: 100,
          height: 100,
        });
      
        if (croppedCanvas) {
          const rotatedCanvas = document.createElement("canvas");
          const ctx = rotatedCanvas.getContext("2d");
          
          if (ctx) {
            const radians = (rotation * Math.PI) / 180;
            rotatedCanvas.width = croppedCanvas.height;
            rotatedCanvas.height = croppedCanvas.width;
            ctx.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);
            ctx.rotate(radians);
            ctx.drawImage(croppedCanvas, -croppedCanvas.width / 2, -croppedCanvas.height / 2);
      
            const croppedImage = rotatedCanvas.toDataURL();
            const fileExt = croppedImage.substring("data:image/".length, croppedImage.indexOf(";base64"));
      
            if (fileExt !== undefined && imageExt.includes(fileExt)) {
              try {
                const response = await CustomerDetailsAPI.updateUserProfileInfo({
                  email: custData.email,
                  user_image: croppedImage,
                });
                localStorage.setItem("profileImage", `${response.imageUrl}?v=${Math.random()}`);
                setUserImage(`${response.imageUrl}?v=${Math.random()}`);
                await dispatch(setCustomer({ email: custData.email, avatar_url: response.imageUrl }));
              } catch (e) {
                setErrorMsg(errorMsg);
              } finally {
                await dispatch(loadCustomerDetails(custData.email));
                setBusy(false);
                setShowModal(false);
                setRotation(0);
              }
            } else {
              setErrorMsg("An error has occurred. Please try again later.");
            }
          } else {
            setErrorMsg("Failed to get canvas context.");
          }
        }
      };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} className='pb-5 profile-modal' centered>
            <Modal.Header closeButton className='text-white px-4 border-bottom-0'>
                <Modal.Title>Edit Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center d-flex justify-content-center align-items-center p-4">
                <div className='cropper-wrap'>
                    <div className='cropper-wrap-inner'>
                        <Cropper
                            ref={cropperRef}
                            style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "50%",
                                transform: `rotate(${rotation}deg)`
                            }}
                            aspectRatio={1}
                            viewMode={1}
                            cropBoxResizable={false}
                            background={false}
                            zoomTo={zoom}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={false}
                            rotateTo={rotation}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='w-100 px-3'>
                    <Row className='align-items-center'>
                        <Col xs={9} sm={10}>
                            <div className="controls-zoom">
                                <div className='d-flex align-items-center flex-grow-1'>
                                    <button className='zoom-in' onClick={handleZoomOut}></button>
                                    <input
                                        type="range"
                                        min="0.1"
                                        max="3"
                                        step="0.01"
                                        value={zoom}
                                        onChange={handleZoomChange}
                                    />
                                    <button className='zoom-out' onClick={handleZoomIn}></button>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className="px-2">{(zoom * 100).toFixed(0)}%</div>
                                    <Image src={RotateIMG} alt="RotateIMG" className='ms-2 controls-rotate' onClick={handleRotate} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={2}>
                            <Button
                                variant='success'
                                type="submit"
                                className={`${busy ? 'px-2' : 'px-3'}`}
                                onClick={handleSaveImage}>
                                {busy ? 'Saving..' : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
